.procedures_page {
    .questions_container {
        margin-top: -3em;

        @media screen and (min-width: 768px) {
            margin-top: -12em;
        }
    }

    .footer{
        background-color: var(--color-gray-light);
    }
}