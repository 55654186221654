.accordeon_component {
    .item {
        border-bottom: solid thin #CDD6DA;

        &:hover {
            cursor: pointer;
        }

        .header {
            padding: 1em 0;
        }

        &.active {
            // border-top: solid thin #CDD6DA;
            padding: auto 16px;

            .header {
                border-bottom: solid thin #CDD6DA;
            }

            .content {
                display: block;
            }
        }

        &:first-of-type {
            border-top: solid thin #CDD6DA;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                flex-grow: 1;
                padding-right: 1em;
            }
        }

        .content {
            display: none;
        }
    }
}