.workshop_and_service {
    .workshop_and_service_banner {
        position: relative;
        width: 100%;
        aspect-ratio: 16 / 5;

        .banner {
            object-fit: cover;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        .banner_opacity {
            z-index: -1;
            width: 100%;
            height: 100%;
            background-color: #00000088;
        }

        .info_banner {
            display: flex;
            height: 100%;
            align-items: end;
            justify-content: start;
            padding: 0 8%;
            padding-bottom: 32px;

            h2 {
                font-size: 60px;
                color: #fff;
                font-weight: 500;
            }

            strong {
                color: #ec671b;
                font-weight: bold;
            }
        }

        @media screen and (max-width: 768px) {
            aspect-ratio: 16 / 6;

            .info_banner {
                justify-content: center;
                align-items: center;
                padding-bottom: 0;

                h2 {
                    font-size: 48px;
                    text-align: center;
                }
            }

        }

        @media screen and (max-width: 480px) {
            aspect-ratio: 16 / 7;

            .info_banner {
                justify-content: center;
                align-items: center;

                h2 {
                    font-size: 40px;
                    text-align: center;
                }
            }

        }
    }

    .workshop_and_service_description {
        background-color: #f6f7f9;

        .info_description {
            padding: 0 8%;

            h3 {
                font-size: 32px;
            }

            p {
                font-size: 18px;
            }

            strong {
                font-weight: 900;
            }
        }
    }

    .workshop_and_service_drop_lists,
    .workshop_and_service_questions {
        background-color: #f6f7f9;

        .drop_lists {
            padding: 0 8%;

            h3 {
                font-size: 32px;
                font-weight: bold;
            }

            span {
                color: #ec671b;
            }

            .title_drop_list {
                font-size: 32px;
                font-weight: bold;
            }

            .subtitle_drop_list {
                font-size: 18px;
                font-weight: bold;
            }

            .item_drop_list {
                font-size: 18px;
                color: #000;
            }
        }
    }

    .workshop_and_service_gallery {
        padding: 0 8%;
        gap: 16px;

        .image {
            object-fit: cover;
        }

        .image_1 {
            width: calc(50% - 8px);
        }

        .image_2 {
            width: calc(50% - 8px);
        }

        .image_3 {
            width: calc(45% - 8px);
        }

        .image_4 {
            width: calc(55% - 8px);
        }

        @media screen and (max-width: 768px) {

            .image_1 {
                width: 100%;
            }

            .image_2 {
                width: 100%;
            }

            .image_3 {
                width: 100%;
            }

            .image_4 {
                width: 100%;
            }
        }
    }

    .workshop_and_service_form {
        padding: 4% 0;
        background-image: url("../../../../../public/assets/workshopAndService/muscular-car.webp");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .workshop_and_service_blog {
        .outstanding_container {
            margin-top: -3em;
            max-width: 546px;

            @media screen and (min-width: 768px) {
                margin-top: -12em;
            }
        }

        .outstanding_blog {
            margin-top: -20%;
            min-width: 100%;
            min-height: 200px;
        }

        .outstandingBlog-main-image {
            width: 100%;
            min-height: 254px;
            max-height: 400px;
        }

        .outstanding-title {
            font-size: 22px;
            color: #1e1e1e;
            margin-top: 16px !important;
            max-width: 24ch;
        }

        .blog_info_user {
            margin-top: 24px !important;
        }

        .date {
            font-size: 14px !important;
        }

        @media (min-width: 768px) {
            .outstanding_blog {
                width: 100%;
                margin-top: 20px;
            }

            .outstanding-title {
                font-size: 36px;
            }
        }

        @media (min-width: 1440px) {
            .outstandingBlog-main-image {
                width: 100%;
                max-height: 600px;
            }
        }
    }

}