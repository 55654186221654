.check_recovery_code_page {
    .container-page {
        height: 100vh;
    }
    .content_page {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        input.form-control {
            background-color: var(--color-gray-light);
        }
    }

    .footer {
        background-color: var(--color-gray-light);
    }

    @media (min-width: 768px) {
    }
    @media (min-width: 1000px) {
        .container-page {
            display: flex;
            align-items: center;
        }
        .img-cover {
            border-radius: 10px;
        }
    }
}
