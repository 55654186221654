.box {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;

    .closer {
        background-color: #00000080;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .close {
        position: absolute;
        top: 3em;
        right: 3em;
    }

    .modal-amount {
        width: 100%;
        padding: 70px 20px;
        background-color: white;
        margin-top: 80px;
        display: flex;
        flex-direction: column;

        .modal-buttons {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;

            .bg-color {
                background: #ccc;
            }
        }
    }
}

.modal-title {
    text-align: center;
}

.icon-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}



.btn-modal {
    align-self: center;
}

.modal-amount .btn {
    width: 120px !important;
}

@media (min-width: 601px) {
    .closer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-amount {
        max-width: 600px;
        margin: auto;
        border-radius: 10px;
    }

    .input-box {
        min-width: 460px;
        max-width: 460px;
        margin: auto;
    }

    .modal-buttons {
        margin-top: 32px;
    }
}