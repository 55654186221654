.details_list {
    border-radius: 5px;
    margin-top:-10;
    .item {
        // overflow: scroll;
    }

    .item::-webkit-scrollbar {
        // display: none;
    }

    .items_list {
        .item,
        p {
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                margin-right: 8px;
            }
        }

        .price {
            span {
                color: #ec671b;
            }
        }

        .financing_text {
            cursor: pointer;
            text-wrap: nowrap;

            span {
                color: #ec671b;
            }
        }

        hr {
            border: none;
            height: 1px;
            background-color: rgb(165, 165, 165);
            margin: 16px 0;
        }
    }

    @media (min-width: 1400px) {
        .items_list {
            .item,
            p {
                font-size: 16px;
                font-weight: 400;
            }

            hr {
                margin: 16px 0;
            }
        }
    }
}

.warranty_tool_tip_detail {
    display: block;
    left: -35px;
    margin-top: 10px;
    color: #666c89;
    width: 235px;
    background: #f6f7f9;
    padding: 5px 10px;
    border: 1px solid #90a3bf80;
    border-radius: 5px;
    text-align: center;
    z-index: 10000;
}

.warranty_tool_tip_detail::after {
    content: "";
    position: absolute;
    top: -20px;
    left: 15%;
    transform: translateX(-15%);
    border: 10px solid;
    border-color: transparent transparent #f6f7f9 transparent;
}

.warranty_tool_tip_detail::before {
    content: "";
    position: absolute;
    top: -21px;
    left: 15%;
    transform: translateX(-15%);
    border: 10px solid;
    border-color: transparent transparent #90a3bf80 transparent;
}
