.card_carousel {
    display: block;
    border: none;
    box-shadow: 0px 2px 4px 0px #efefef;
    position: relative;
    margin: 0 auto;
    width: 340px;

    .card-body {
        height: 260px;
        background: #fff;
    }

    .img_car {
        width: 100%;
        height: 220px;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        object-fit: cover;
    }

    .botton_container {
        height: 42px;
        padding-bottom: 10px;
    }

    .warranty_icon {
        background-color: #c5f466;
        width: 94px;
        height: 30px;
        padding: 4px 16px 4px 8px;
        border: 1px solid #1e1e1e;
        border-radius: 50px;
        color: #1e1e1e;
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        gap: 4px;
        position: absolute;
        top: 8px;
        right: 8px;
    }
}

.tag {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    padding: 5px 16px;
    border-top-left-radius: 8px;
}

.tag-2 {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.4em 0.8em;
    border-top-left-radius: 0.5rem;
}
