.success_modal_container {
    position: fixed;
    top: 0;
    z-index: 9999999;
    background-color: #00000099;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    .success_modal {
        width: 100%;
        max-width: 400px;
        padding: 30px 40px;
        background-color: #fff;
        border-radius: 8px;

        .check_img{
            width: 50px;
            height: 50px;
        }
    }
}