.seo_accordeon_component {
    .item {
        border-bottom: solid thin #CDD6DA;
        padding-bottom: 1em;
        padding-top: 1em;

        &:hover {
            cursor: pointer;
        }

        &.active {
            border-top: solid thin #CDD6DA;

            .header {
                padding-bottom: 0.75em;
                border-bottom: solid thin #CDD6DA;
            }

            .content {
                display: block;
            }
        }

        &:first-of-type {
            border-top: solid thin #CDD6DA;
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;


            .title {
                flex-grow: 1;
                padding-right: 1em;
                font-size: 18px;
            }
        }

        .content {
            display: none;
        }

        .accordeon_image{
            width: 300px;
            height: 200px;
            border-radius: 5px;
            object-fit: cover;
        }
    }
}

