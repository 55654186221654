.layout_container {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .content_app {
        flex-grow: 1;
        overflow-y: auto;
        width: 100%;
        overflow-x: hidden;
    }
}

.video_conference {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100000;
}
