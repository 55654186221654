.financing {
    display: block;
    background-image: url("./../../../../../public/assets/home/financiacion.svg");
    background-size: cover;
    min-height: 80px;
    max-width: 900px;

    .description {
        font-size: 36px;
    }

    .wcar-icon {
        left: 2%;
        top: 20%;
    }

    .btn-custom{
        margin-left: 16px;
        margin: 16px 0 16px 16px;
    }
}

@media screen and (max-width: 768px) {
    .financing {
        height: 290px;
        margin: 0 5%;

        .wcar-icon {
            left: 5%;
            top: 5%;
        }

        .btn-custom{
            margin: 16px 0 0 0;
        }
    }
}
