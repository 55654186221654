.card_container {
    padding: 0;
    .card_part_one {
       z-index: 1;
       width: 50%;
    }
    .card_part_two {
        width: 45%;
        position: inherit;
        left:-30px;
        top:-30px;
        height: 100%;

        .card_phone_img {
            height: 450px;
            width: 100%;
            position: inherit;
            bottom:-30px;
        }
    }
    
}

@media screen and (max-width: 950px) {
    .card_container {
        width: 100% !important;
        flex-direction:column;
        justify-content: center;
        align-items: center;
        display:flex;
        padding-bottom:30px;
        .card_part_one {
            width: 100%;
            flex-direction:column;
            padding: 10px;
        
           
        }

        .card_part_two {
            display: none;
        }

        .svg_wcar {
            display: none;
        }
    }
}
