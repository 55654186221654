.fixed_container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00000080;
    overflow-y: auto;

    .modal_expertisen {
        padding: 40px 30px;
        border-radius: 10px;
        margin: 0 auto;
        background-color: white;
        width: 100%;
        max-width: 550px;
    }

    @media screen and (max-height: 490px) {
        align-items: start;
    }
}