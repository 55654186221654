.card_container {
    padding: 30px;
    display: flex;
    overflow: hidden;
    position: relative;

    .svg_wcar {
        position: absolute;
        bottom: 70px;
        z-index: 0;
        height: 150px;
    }

    .card_part_one {
        z-index: 999999999;
        width: 60%;

        h2 {
            letter-spacing: 1px;
            padding-bottom: 48px;
            font-size: 36px;
            color: white;

            i {
                color: #00fefe;
                font-weight: 600;
            }
        }

        .item_list {
            display: flex;
            padding: 4px 0;

            p {
                font-size: 24px;
                font-weight: 400;
                color: white;
            }

            img {
                width: 25px;
                height: 25px;
                margin-right: 16px;
            }
        }

        button {
            margin-top: 48px;
        }
    }

    .card_part_two {
        z-index: 1;
        width: 40%;
        display: flex;
        align-items: start;
        justify-content: center;

        .card_phone_img {
            width: 80%;
        }
    }
}

@media screen and (max-width: 768px) {
    .card_container {
        width: 100% !important;

        .card_part_one {
            width: 100%;
        }

        .card_part_two {
            display: none;
        }

        .svg_wcar {
            display: none;
        }
    }
}
