.send_recovery_code_page {
    .content_page {
        input.form-control {
            background-color: var(--color-gray-light);
        }

    }

    .footer {
        background-color: var(--color-gray-light);
    }
}