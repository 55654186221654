.card_financing_banner {
    .info_card {
        width: 50%;

        .title_container {
            padding: 16px;
            padding-bottom: 40px;

            .title {
                font-size: 42px;
                color: #fff;
                font-weight: 800;

                i {
                    color: #ec671b;
                    font-weight: 500;
                }
            }
        }

        .content_container {
            padding: 0 16px;

            .item_list {
                display: flex;
                padding: 4px 0;

                p {
                    color: #fff;
                    font-size: 24px;
                    display: flex;
                    align-items: center;
                }

                img {
                    width: 25px;
                    height: 25px;
                    margin-right: 16px;
                }
            }
        }

        .button_container {
            padding: 40px 16px;
        }
    }

    .img_card {
        width: 40%;
    }

    @media screen and (max-width: 768px) {
        .info_card {
            width: 100%;
        }

        .img_card {
            display: none;
        }
    }
}

.sub_title_2 {
    font-family: "Urbanist";
    color: #1e1e1e;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    margin-left: 56px;
    z-index: 1;

    i {
        color: #ec671b;
        font-weight: 400;
    }}