.zoom_car_component {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    z-index: 9999;

    .closer {
        background-color: #00000080;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .close {
        position: absolute;
        top: 3em;
        right: 3em;
    }

    .arrow_slider {
        // width: 6em;
        // height: 6em;
        font-size: 2em;
        background-color: #00000040;
        color: white;
        opacity: 1;

        &.previous {
            position: absolute;
            top: 50%;
            left: 2em;
        }

        &.next {
            position: absolute;
            top: 50%;
            right: 2em;
        }

        &.disabled {
            opacity: 0.3;
        }
    }

    .zoom_car_component_image_container {
        position: relative;
        padding: 2em;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            position: relative;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.pdf-viewer {
    width: 100%;
    height: 100%;
    z-index: 99999;
}

@media (min-width: 600px) {
    .pdf-viewer {
        width: 80%;
        height: 90%;
    }
}
