.car_carousel_images_component {
    user-select: none;

    .bg_slide {
        position: fixed;
        z-index: 1000000000000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.8);
        display: flex;
        justify-content: center;
        align-items: center;

        .closed {
            position: absolute;
            top: 5%;
            right: 5%;
            cursor: pointer;
            color: white;
            font-size: 40px;
            background-color: rgba($color: #000000, $alpha: 0.2);
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .slide_container {
        width: 60%; // porcentaje screen pantallas screens: 87% dev: 60%
        
    }

    .zoom_shower {
        display: block;
        position: absolute;
        height: 100%;
        aspect-ratio: 1/1;
        top: 0;
        left: calc(100% + 2em);
        z-index: 1000;
        overflow: hidden;

    
    }

    .zoom_wrapper {
        cursor: zoom-in;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;

        img {
            border: 1px solid green;
        }

        .zoom_loop,
        .zoom_shower {
            display: none;
        }

        //zoom
        @media screen and (min-width: 768px) {
            .zoom_loop {
                display: block;
                position: absolute;
                height: 40%;
                aspect-ratio: 1/1;

                .zoom_box {
                    background-color: #00000040;
                    margin-left: -50%;
                    margin-top: -50%;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .container_image_showing {
        // max-height: 600px;
        position: relative;

        .arrows_container {
            z-index: 101;
            position: absolute;
            right: 10px;
            bottom: 1em;
            display: flex;
            align-items: center;

            @media (min-width: 768px) {
                right: 80px;
            }

            @media (min-width: 1024px) {
                right: 10px;
            }

            @media (min-width: 1280px) {
                right: 30px;
            }

            @media (min-width: 1439px) {
                right: 30px;
            }
        }

        .carousel_thumbail {
            // max-height: 450px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            overflow: hidden;
            // border: 1px solid green;

            &.active img {
                border: solid 2px var(--color-orange);
            }

            img {
                user-select: none;
                -webkit-user-drag: none;
                height: 100%;
                max-width: 100%;
                object-fit: cover;

                @media (min-width: 768px) {
                    max-width: 100%;
                    max-height: 100%;
                }

                @media (min-width: 1440px) {
                    max-width: 987px;
                    max-height: 100%;
                }
            }
        }
    }

    .images_thumbails {
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: auto;
        max-width: 100%;
        margin: auto;

        @media (min-width: 768px) {
            max-width: 100%;
        }

        .carousel_thumbail {
            &.active img {
                border: solid 2px var(--color-orange);
            }

            img {
                margin: 10px;
                border-radius: 15px;
                height: 70px;
                width: 130px;
                object-fit: cover;

                &:hover {
                    cursor: pointer;
                }

                &.active {
                    border: solid 2px var(--color-orange);
                }
            }
        }
    }
}

.tag {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    padding: 5px 16px;
    border-top-left-radius: 8px;
}

.tag-2 {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.4em 0.8em;
    border-top-left-radius: 0.5rem;
}

.tag-text {
    font-weight: 600;
    letter-spacing: 0.5px;
}

.stamp {
    display: block;
    position: absolute;
    bottom: 40px;
    left: 40px;
    width: 80px;

    img {
        width: 100%;
    }
}

@media screen and (max-width: 678px) {
    .stamp {
        bottom: 10px;
        left: 10px;
        width: 60px;
    }

    .car_carousel_images_component {
        .slide_container {
            width: 100%;
        }
    }
}

@media (min-width: 1300px) {
    .tag {
        padding: 8px 24px;
    }
    

    .tag-text {
        font-size: 16px;
    }
}
