.modal_container {
    width: 480px;
    border-radius: 12px;
    background-image: url("../../../../../public/assets/icons_contact/Rectangle.png");
    background-size: cover;

    .top {
        display: flex;
        justify-content: center;
        padding: 32px 24px 12px 24px;
    }

    .middle {
        padding: 0 24px;

        p {
            font-family: Urbanist;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: #90a3bf;
        }

        strong {
            font-family: Urbanist;
            font-size: 22px;
            font-weight: 700;
            line-height: 30px;
            color: #fff;
        }
    }

    .bottom {
        display: flex;
        justify-content: center;
        padding: 32px 24px 24px 24px;
    }
}
