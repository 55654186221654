.home_page {
    section.first_section {
        z-index: 0;
        background-color: var(--color-gray-light);

        .text-header {
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;
            color: #2b2b2b;

            span {
                font-weight: 400;
                font-style: italic;
            }
        }

        .br-el {
            display: none;
        }

        .wcar-absolute {
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            color: #666c89;
        }

        & > * {
            position: relative;
            z-index: 0;
        }

        .title_orange {
            font-weight: 400;
            font-family: "Urbanist";
        }

        .img_car_01_container {
            margin-top: -120px;
        }

        .img_car_01 {
            max-width: 2000px;
            height: 800px;
            margin-top: -60px;
        }

        @media screen and (max-width: 768px) {
            .img_car_01_container {
                margin-top: 0;
            }

            .img_car_01 {
                width: calc(100% + 75vw);
                height: inherit;
                margin-left: -50vw;
                margin-right: -25vw;
            }
        }
    }

    .second_section {
        .text_wcar {
            font-family: "Urbanist";
            font-size: 36px;
            font-weight: 700;
            color: #fff;
            line-height: 44px;

            i {
                font-weight: 400;
            }
        }

        .sub_title_1 {
            font-weight: 700;

            i {
                font-weight: 400;
            }
        }

        .yellow_square {
            background-color: #fcff5c;
            height: 100px;
            width: 200px;
            left: -32%;
        }

        .yellow_square_2 {
            background-color: #fcff5c;
            height: 55px;
            width: 125px;
            right: 0;
            bottom: 0;
        }

        .blue_square {
            background-color: #00fefe;
            height: 55px;
            width: 55px;
            bottom: 55px;
            right: 0;
        }

        .sub_title_2 {
            font-family: "Urbanist";
            color: #1e1e1e;
            font-weight: 700;
            font-size: 36px;
            line-height: 44px;
            margin-left: 56px;
            z-index: 1;

            i {
                color: #ec671b;
                font-weight: 400;
            }
        }

        .sub_title_3 {
            height: 100px;
            margin-left: 25%;

            h2 {
                margin: 0;
                font-family: "Urbanist";
                color: #1e1e1e;
                font-weight: 700;
                font-size: 36px;
                line-height: 44px;
            }

            i {
                color: #ec671b;
                font-weight: 400;
            }
        }

        .us_wcar {
            width: 79%;
            background-color: #1e1e1e;
        }

        .car_3 {
            background-image: url(../../../../../public/assets/home/car_03.webp);
            background-repeat: no-repeat;
            background-size: cover;
            top: 100px;
            left: -120%;
            right: 19%;
            bottom: -100%;
        }

        .wcar_img_2 {
            background-image: url(../../../../../public/assets/home/car_02.webp);
            background-repeat: no-repeat;
            background-size: cover;
            top: -7.5%;
            left: -20%;
            right: 0;
            bottom: 25%;
        }

        .car_4 {
            left: 90px;
            z-index: 0;
        }

        .lines_3 {
            background-image: url(../../../../../public/assets/home/lines_3.svg);
            background-repeat: no-repeat;
            z-index: 9999999;
            left: -169%;
            top: 30%;
            right: 0;
            bottom: 0;
        }

        @media screen and (max-width: 1399px) {
            .wcar_img_2 {
                top: -6.6%;
            }

            .us_wcar {
                width: 74.3%;
            }

            .sub_title_3 {
                margin-left: 30%;
                margin-bottom: 5%;
            }

            .car_3 {
                right: 0;
                bottom: -95%;
            }
        }

        @media screen and (max-width: 1300px) {
            .wcar_img_2 {
                top: -6.9%;
            }
        }

        @media screen and (max-width: 1199px) {
            .wcar_img_2 {
                top: -6.3%;
            }

            .car_3 {
                bottom: -110%;
            }

            .lines_3 {
                left: -171.5%;
            }
        }

        @media screen and (max-width: 991px) {
            .wcar_img_2 {
                top: -5.1%;
            }

            .us_wcar {
                width: 74%;
            }

            .yellow_square {
                height: 100px;
                width: 150px;
                left: -37%;
            }

            .car_3 {
                bottom: -80%;
            }

            .car_4 {
                left: 60px;
                z-index: 0;
            }

            .lines_3 {
                left: -176%;
            }
        }

        @media screen and (max-width: 767px) {
            .wcar_img_2 {
                display: none;
            }

            .us_wcar {
                width: 100%;
            }

            .sub_title_3 {
                margin-left: 3%;
                height: 80px;
            }
        }
    }
}

.third_section {
    background-color: #f6f7f9;
}

@media screen and (min-width: 768px) {
    .home_page {
        section.first_section {
            .h1 {
                font-size: 4em;
                line-height: 1em;
                font-weight: bold;
            }

            margin-bottom: 10em;

            .img_car_01 {
                margin-left: 0;
                margin-right: 0;
                left: 0;
                right: -20vw;
                bottom: -50px;
                width: calc(100% + 20vw);
                object-fit: cover;
                z-index: 0;
            }
            .text-header {
                font-size: 44px;
                line-height: 49px;
                font-weight: 700;
                color: #2b2b2b;

                span {
                    font-weight: 400;
                    font-style: italic;
                }
                .text-header-italic {
                    font-size: 47px;
                    line-height: 52px;
                }
                .text-header-normalized {
                    font-style: normal !important;
                    font-weight: 600;
                }
            }
            .br-el {
                display: block;
            }
        }
    }

    .home_page ~ .footer {
        background-color: var(--color-gray-light) !important;
    }
}

@media (min-width: 1100px) {
    .no_scrolled {
        .home_page {
            padding-top: 100px;
        }
    }
}

// .home_page {
//     section.first_section {
//         background-color: var(--color-gray-light);
//         margin-bottom: 80px;

//         .text-header {
//             font-size: 60px;
//             line-height: 65px;
//             font-weight: 600;
//             color: #2B2B2B;

//             span {
//                 font-weight: 400;
//                 font-style: italic;
//             }
//         }

//         .wcar-absolute {
//             font-size: 14px;
//             font-weight: 700;
//             line-height: 22px;
//             color: #666C89;
//         }

//         &>* {
//             position: relative;
//             z-index: 0;
//         }

//         .title_orange {
//             font-weight: 400;
//             font-family: "Urbanist";
//         }

//         .img_car_01_container {
//             margin-top: -150px;
//             height: 750px;
//             overflow-x: hidden;
//         }

//         .img_car_01 {
//             max-width: 1452px;
//             width: 100%;
//             background-repeat: no-repeat;
//             background-size: cover;
//             background-position: center;
//         }

//         @media screen and (max-width: 768px) {
//             .img_car_01_container{
//                 margin-top: -250px;
//             }

//             .img_car_01 {
//                 width: calc(100%);
//                 background-size: auto;

//             }
//         }
//     }

//     @media screen and (max-width: 768px) {

//         section.first_section{
//             margin-bottom: 0;
//         }
//     }

//     .second_section {
//         .text_wcar {
//             font-family: "Urbanist";
//             font-size: 36px;
//             font-weight: 700;
//             color: #fff;
//             line-height: 44px;

//             i {
//                 font-weight: 400;
//             }
//         }

//         .sub_title_1 {
//             font-weight: 700;

//             i {
//                 font-weight: 400;
//             }
//         }

//         .yellow_square {
//             background-color: #FCFF5C;
//             height: 100px;
//             width: 200px;
//             left: -32%;
//         }

//         .yellow_square_2 {
//             background-color: #FCFF5C;
//             height: 55px;
//             width: 125px;
//             right: 0;
//             bottom: 0;
//         }

//         .blue_square {
//             background-color: #00FEFE;
//             height: 55px;
//             width: 55px;
//             bottom: 55px;
//             right: 0;
//         }

//         .sub_title_2 {
//             font-family: "Urbanist";
//             color: #1e1e1e;
//             font-weight: 700;
//             font-size: 36px;
//             line-height: 44px;
//             margin-left: 56px;
//             z-index: 1;

//             i {
//                 color: #ec671b;
//                 font-weight: 400;
//             }
//         }

//         .sub_title_3 {
//             height: 100px;
//             margin-left: 25%;

//             h2 {

//                 margin: 0;
//                 font-family: "Urbanist";
//                 color: #1e1e1e;
//                 font-weight: 700;
//                 font-size: 36px;
//                 line-height: 44px;
//             }

//             i {
//                 color: #ec671b;
//                 font-weight: 400;
//             }
//         }

//         .us_wcar {
//             width: 79%;
//             background-color: #1E1E1E;
//         }

//         .car_3 {
//             background-image: url(../../../../../public/assets/home/car_03.png);
//             background-repeat: no-repeat;
//             background-size: cover;
//             top: 100px;
//             left: -120%;
//             right: 19%;
//             bottom: -100%;
//         }

//         .wcar_img_2 {
//             background-image: url(../../../../../public/assets/home/car_02.png);
//             background-repeat: no-repeat;
//             background-size: cover;
//             top: 0;
//             left: -20%;
//             right: 0;
//             bottom: 25%;
//         }

//         .car_4 {
//             left: 90px;
//             z-index: 0;
//         }

//         .lines_3 {
//             background-image: url(../../../../../public/assets/home/lines_3.svg);
//             background-repeat: no-repeat;
//             z-index: 9999999;
//             left: -169%;
//             top: 30%;
//             right: 0;
//             bottom: 0;
//         }

//         @media screen and (max-width: 1399px) {
//             .us_wcar {
//                 width: 74.3%;
//             }

//             .sub_title_3 {
//                 margin-left: 30%;
//                 margin-bottom: 5%;
//             }

//             .car_3 {
//                 right: 0;
//                 bottom: -95%;
//             }
//         }

//         @media screen and (max-width: 1199px) {
//             .car_3 {
//                 bottom: -110%;
//             }

//             .lines_3 {
//                 left: -171.5%;
//             }

//         }

//         @media screen and (max-width: 991px) {
//             .us_wcar {
//                 width: 74%;
//             }

//             .yellow_square {
//                 height: 100px;
//                 width: 150px;
//                 left: -37%;
//             }

//             .car_3 {
//                 bottom: -80%;
//             }

//             .car_4 {
//                 left: 60px;
//                 z-index: 0;
//             }

//             .lines_3 {
//                 left: -176%;
//             }
//         }

//         @media screen and (max-width: 767px) {
//             .wcar_img_2 {
//                 display: none;
//             }

//             .us_wcar {
//                 width: 100%;
//             }

//             .sub_title_3 {
//                 margin-left: 3%;
//                 height: 80px;
//             }
//         }
//     }
// }

// .third_section {
//     background-color: #F6F7F9;
// }

// @media screen and (min-width: 768px) {
//     .home_page {
//         section.first_section {
//             .h1 {
//                 font-size: 4em;
//                 line-height: 1em;
//                 font-weight: bold;
//             }
//         }
//     }

//     .home_page~.footer {
//         background-color: var(--color-gray-light) !important;
//     }
// }
