.button-link {
    display: flex;
    align-items: center;
    width: 271px;
    height: 48px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-left: 32px;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    color: #1E1E1E;
    font-family: Urbanist;
    text-transform: uppercase;
}