.form_container {
    form {
        .form-control {
            background-color: var(--color-dark-gray);
            color: #fff;

            &::placeholder {
                color: #ffffff70;
            }
        }
    }
}