.images_styles {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
    background-size: contain;
    height: auto;
    width: 100%;
    aspect-ratio: 16 / 12;
}


.custom-arrow-prev,
.custom-arrow-next {
    background-color: rgba($color: #000000, $alpha: 0.2);
    width: 50px;
    height: 50px;
    position: absolute;

    svg {
        color: #fff;
        width: 100%;
        height: 100%;

    }
}

.custom-arrow-prev {
    left: 2%;
    top: 40%;
}

.custom-arrow-next {
    right: 5%;
    top: 40%;
}

@media screen and (max-width: "678px") {

    .custom-arrow-prev,
    .custom-arrow-next {
        display: none;
    }
}