.sell_your_car_page {
    section.section_1 {

    }

    @media screen and (min-width: 768px) {
        .img_section_1 {
            position: absolute;
            right: -2em;
            top: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .banner_background {
            height: auto;
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .img_banner_1 {
            display: none;
        }

        .banner_background {
            flex: 1;
            height: 8vh;
        }
    }

    .banner_background {
        background-color: #EC671B;
    }

    .banner_section {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 100%;
        position: relative;
    }

    .banner_content {
        @extend .banner_section;
        color: white;
        height: 100%;
        font-size: 1.2rem;
    }

    section.banner_section {
        div.banner_content {
            p.banner_text {
                margin-bottom: 0;
            }
        }
    }

    section.sell_section {

        position: relative;

        div.image_decoration {
            height: auto;
            width: 100%;
            margin-bottom: 1rem;

            @media screen and (min-width: 576px) {

                img.img_decoration_md {
                    display: block;
                    width: 100%;
                }

                img.img_decoration_sm {
                    display: none;
                }

            }

            @media screen and (max-width: 576px) {

                img.img_decoration_md {
                    display: none;
                }

                img.img_decoration_sm {
                    display: block;
                    width: 100%;
                }
            }
        }


        div.sell_car_container {
            position: relative;
            background-color: var(--color-dark-gray);
            display: flex;
            flex-direction: row;

            img.lines_zigs_sell {

                position: absolute;
                z-index: 1;
            }

            @media screen and (min-width: 768px) {
                div.sell_car_description_container {
                    padding: 5.5rem 23rem 5.5rem 4.5rem;

                    img.little_sell_box {
                        display: none;
                    }
                }

                img.lines_zigs_sell {
                    right: 16rem;
                    top: 28rem;
                }

                img.lines_sell {
                    position: absolute;
                    z-index: 1;
                    right: 0;
                    top: -9rem;
                    width: 7rem;
                    height: 28rem;
                }

            }

            @media screen and (max-width: 768px) {

                flex-direction: column;

                div.sell_car_description_container {
                    padding: 6rem 2rem 7rem 2rem;
                    align-items: center;
                    position: relative;

                    img.little_sell_box {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        height: 5rem;
                    }
                }

                img.lines_zigs_sell {
                    top: 1rem;
                    right: 2.5rem;
                    transform: rotate(270deg);
                }

                img.lines_sell {
                    display: none;
                }
            }

            @media screen and (min-width:768px) and (max-width: 1200px) {
                div.sell_car_description_container {
                    padding: 5rem 2rem 3rem 2rem;
                }

                img.lines_sell {
                    display: none;
                }

                img.lines_zigs_sell {
                    transform: rotate(270deg);
                    right: 2.5rem;
                    top: 0.5rem;
                }

            }

            div.sell_car_description_container {

                color: white;

                div.sell_title_container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 1rem;
                }

                h1.sell_car_title {
                    font-weight: 700;
                }

                h1.sell_car_subtitle {
                    font-weight: 400;
                    font-style: italic;
                }

            }
        }

    }
}