.detailed_car_page {
    .carCarouselContainer {
        padding: 0 16px;
        // border: 1px solid red;
    }

    .btn-back-container {
        margin-bottom: 10px;
        padding-left: 10px;

        button {
            display: flex;
            align-items: center;
            color: #484848;
            font-weight: 600;
            background-color: transparent;
        }
    }

    .section_2 {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: black;
            right: 50%;
            background-size: cover;
            z-index: -1;
        }

        @media screen and (max-width: 768px) {
            &::before {
                content: none;
                display: none;
            }
        }

        .bg_black {
            position: relative;

            &>img.bg_img {
                position: absolute;
                left: 0;
                bottom: 0;
                top: 0;
            }
        }
    }

    .car-price {
        font-size: 32px;
        color: #1e1e1e;
        margin-top: 8px;
    }

    .car-spec {
        font-size: 14px;
        color: #666c89;
        font-weight: 500;
        line-height: 22px;
    }

    .car-spec-strong {
        font-size: 16px;
        color: #666c89;
        font-weight: 700;
    }

    .btn-detailed-car {
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        display: flex;
        align-items: center;
    }

    .text-spec {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #90aebf;
    }

    .description {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
    }

    .item {
        margin: 0;
    }

    .item:nth-last-child(1),
    .item:nth-last-child(2),
    .item:nth-last-child(3) {
        grid-column: 1/4;
    }

    .banner-tag-container {
        width: 100%;
        padding: 0 16px;
    }

    .banner-tag {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 8px;
        margin: 0 auto;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        padding: 12px 16px;

        .titleDropDonwList {
            cursor: pointer;

            svg {
                font-size: 26px;
            }
        }

        .container_item {
            background: #fff;
            padding: 16px;
            border-radius: 5px;

            .text-fuera {
                font-style: normal !important;
            }
        }
    }

    .banner-tag p span {
        font-weight: 600;
    }

    .warranty_tool_tip {
        display: block;
        left: 0px;
        color: #666c89;
        margin-top: 10px;
        width: 235px;
        background: #f6f7f9;
        padding: 5px 10px;
        border: 1px solid #90a3bf80;
        border-radius: 5px;
        text-align: center;
        z-index: 10000;
    }

    .eye-person{
        display: none;
    }

    .eye-person1:hover > .eye-person{
        display: block;
    }
    .eye-person:hover{
        display: block;
    }

    .warranty_tool_tip::after {
        content: "";
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        border: 10px solid;
        border-color: transparent transparent #f6f7f9 transparent;
    }

    .warranty_tool_tip::before {
        content: "";
        position: absolute;
        top: -21px;
        left: 50%;
        transform: translateX(-50%);
        border: 10px solid;
        border-color: transparent transparent #90a3bf80 transparent;
    }

    .warranty_tool_tip_list::after{
        content: "";
        position: absolute;
        top: -20px;
        left: 18.5%;
        transform: translateX(-50%);
        border: 10px solid;
        border-color: transparent transparent #f6f7f9 transparent;
    }

    .warranty_tool_tip_list::before {
        content: "";
        position: absolute;
        top: -21px;
        left: 18.5%;
        transform: translateX(-50%);
        border: 10px solid;
        border-color: transparent transparent #90a3bf80 transparent;
    }

    .container_warranty_tool {
        padding-bottom: 10px;

        .warranty_tool_tip_hover {
            display: none;
        }
    }

    .container_warranty_tool {
        &:hover {
            .warranty_tool_tip_hover {
                display: block;
            }
        }
    }

    .warranty {
        cursor: default;
        border-radius: 0;
        border-bottom-left-radius: 2em;
        border-top-right-radius: 2em;
        padding: 0.5em 1em;
        background-color: #c5f466;
        font-weight: 600;
        color: #1e1e1e;
        border: 1px solid #1e1e1e;
        transition: all 0.3s ease-in-out;

        svg {
            width: 2em;
        }
    }

    .warranty2 {
        cursor: default;
        border-radius: 0;
        border-bottom-left-radius: 2em;
        border-top-right-radius: 2em;
        padding: 0.5em 1em;
        background-color: #c5f466;
        color: #1e1e1e;
        border: 1px solid #1e1e1e;
        transition: all 0.3s ease-in-out;

        svg {
            width: 2em;
        }
    }

    .claims {
        display: block;
        background-color: #fff;
        width: 300px;
        max-height: 250px;
        padding: 5px 0;
        margin-top: 5px;
        border-radius: 10px;
        box-shadow: 0px 4px 14px 0px #0000001a;
        overflow: auto;
        z-index: 9999999;

        p {
            display: flex;
            flex-direction: column;
            padding: 12px 16px;
            align-self: stretch;
        }

        .claim_line {
            margin: 0;
            border-color: #c4c4c4;
        }

        .claim_line:last-of-type {
            border: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .description {
        grid-template-columns: 1fr !important;

        .item:nth-last-child(1),
        .item:nth-last-child(2),
        .item:nth-last-child(3) {
            grid-column: 1/2;
        }
    }
}

@media (min-width: 768px) {
    .detailed_car_page {
        .banner-tag-container {
            padding: 0;
        }

        .banner-tag {
            width: 100%;
        }
    }

    .carCarouselContainer {
        padding: 0;
    }

    .detailed_car_container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

@media (max-width: 1440px) {
    .detailed_car_page {
        .banner-tag-container {
            padding-left: 78px;
        }
    }
}

@media (max-width: 1200px) {
    .detailed_car_page {
        .banner-tag-container {
            padding-left: 16px;
        }
    }
}

@media (min-width: 1440px) {
    .detailed_car_page {
        .banner-tag-container {
            padding-left: 20px;
            display: flex;
            justify-content: center;
        }
    }
}

// 767