.services_page {
    .footer {
        background-color: var(--color-gray-light);
    }

    .section_1 {
        position: relative;

        .bg_img {
            object-fit: cover;
        }

        .first_img{
            aspect-ratio: 16/6;
        }

        .content {
            z-index: 1;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
        }
    }

    .section_3 {
        // background-color: #f6f7f9;
        background-color: #fff;

        .line_gray {
            background-color: #90a3bf;

            &_vertical {
                width: 1px;
                height: 4rem;
            }

            &_horizontal {
                height: 1px;
            }
        }

        .body_text {
            font-size: 0.75rem;
        }
    }

    .bg_img_line {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
        width: 21%;
    }

    .services_calculator_form_component {
        height: fit-content;
        max-height: 100%;

        .card_option_picker {
            background-color: var(--color-gray-light);
            color: var(--color-gray-dark);
            border: none;

            &.selected {
                background-color: var(--color-orange);
                color: white;
            }
        }

        .insurance_select {
            background-color: var(--color-gray-light);
            color: var(--color-gray-dark);
            border: none;
        }
    }

    .calculator_card {
        z-index: 1;
    }

    .accordeon_component {
        z-index: 1;
        background-color: white;
    }

    .section_5 {
        background-color: #000;
        padding: 16px 0;
    }
}
