.buy_your_car_modal_container {
    position: fixed;
    top: 0;
    z-index: 9999999;
    background-color: #00000099;
    width: 100%;
    height: 100vh;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    .buy_your_car_modal {
        width: 90%;
        max-width: 700px;
        background-color: #fff;
        position: relative;
        border-radius: 12px;
        padding: 20px;

        .closed {
            align-items: flex-end;
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
            padding-top: 10px;

            .closed_icon {
                cursor: pointer;
                padding: 0 5px;
            }
        }

        form {
            padding: 20px;
        }

        label {
            color: #000;
        }

        .form-group {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .group {
                width: 48%;
            }

            .form-control,
            .form-select {
                width: 100%;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .buy_your_car_modal {
            width: 100%;

            .closed {
                right: -10%;
            }

            .form-group {
                display: flex;
                flex-direction: column;

                .group {
                    width: 100%;
                }
            }
        }
    }

    @media screen and (max-height: 535px) and (min-width: 768px) {
        align-items: start;
    }

    @media screen and (max-height: 780px) and (max-width: 768px) {
        align-items: start;
    }
}