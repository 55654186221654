.card_blog_component {
    .card {
        border: 1px solid #e8e8ea;
    }

    .img_card_blog {
        min-height: 10em;
        border-radius: 5px;
        aspect-ratio: 16/9;
        object-fit: cover;

    }

    .title_card_blog{
        height: 65px;
    }

    .tag {
        border-radius: 5px;
        background-color: var(--color-gray-light);
        color: var(--color-orange);
        padding: 0.3em 0.5em;
        width: fit-content;
        font-size: 14px;
        font-weight: 500;
    }

    .tag-2 {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.4em 0.8em;
        border-top-left-radius: 0.5rem;
    }
}
