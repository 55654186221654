.container_sdk {
    width: 100%;
    height: 100%;
    min-height: 100vh;

    .sdk {
        display: flex;
        flex-direction: column;
        max-width: 1400px;
        height: 100%;
        min-height: 500px;
        padding: 0 20px;
        margin: 0 auto;
    }

    .top {
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;

        img {
            margin-right: 20px;
        }

        p {
            margin-left: 10px;
            color: #eff0fa;
        }
    }

    .middle {
        width: 100%;
        height: calc(100vh - 200px);
        display: flex;
        align-items: center;

        .user_screens {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .my_video_container,
        .participant_video_container {
            // border: 3px solid #2572ed;
            border: 3px solid transparent;
            background-color: #131313;
            border-radius: 8px;
            aspect-ratio: 16 / 9;
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .icon {
                width: 20%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .my_video,
        .participant_video {
            transform: scaleX(-1);
            border-radius: 5px;
            width: 100%;
            aspect-ratio: 16 / 9;
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;

        .button_group {
            display: flex;
            gap: 16px;
        }

        .video_button {
            width: 50px;
            height: 48px;
            border-radius: 8px;
            border: 1px solid;
            display: flex;

            button {
                height: 100%;
                width: 100%;
                background-color: transparent;
            }

            div {
                height: 100%;
                width: 40px;
                background-color: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .display_none {
            visibility: hidden;
        }

        .exit {
            background-color: #c74e5b;
            border: 1px solid #000;

            button {
                border-right: 1px solid #000;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .top {
            justify-content: space-between;

            p {
                display: none;
            }
        }

        .middle {
            min-height: 520px;

            .my_video_container,
            .participant_video_container {
                max-width: 465px;
                min-height: 200px;
            }

            .participants_screen_container {
                max-width: 630px;
            }

        }

        .bottom {
            justify-content: center;
            // padding-bottom: 90px;

            .button_group {
                &:first-of-type {
                    margin-right: 16px;
                }
            }

            .video_button {
                width: 40px;
                height: 40px;
                border-radius: 8px;

                button {
                    width: 100%;
                    border-right: none;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }

                div {
                    display: none;
                }
            }

            .display_none {
                display: none;
            }

            .exit {
                background-color: #c74e5b;
                border: 1px solid #000;

                button {
                    border-right: 1px solid #000;
                }
            }
        }
    }
}