.navbar {
    background-color: #fff;
    position: relative;
    z-index: 1;

    .navbar_icons {
        position: absolute;
        right: 1em;
        top: 1em;
        z-index: 999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
    }

    .dropdown-container {
        z-index: 999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
        position: relative;
        display: inline-block;
    }

    /* Style for the dropdown content (hidden by default) */
    .dropdown {
        position: relative;

        &:hover {
            cursor: pointer;

            &>.dropdown_content {
                display: flex;
                flex-direction: column;
                position: absolute;
                background-color: white;
                top: 90%;
                z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999;
                right: 0;
                width: fit-content;

                &.right {
                    left: 100%;
                    top: 0;
                    right: inherit;
                }

                a {
                    &:hover {
                        background-color: #ec671b22;
                    }

                    padding: 1em;
                    white-space: nowrap;
                }
            }
        }

        &>.dropdown_content {
            display: none;
        }
    }

    .account-mobile {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 10px;

        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            i {
                color: #fff;
            }
        }
    }
}

.logo {
    width: 121px;
    height: 48px;
}

.banner-top {
    cursor: pointer;
    width: 100%;
    min-height: 60px;
    max-height: 60px;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    padding-left: 16px;

    .end-text {
        display: none;
    }

    h2 {
        font-size: 16px;
        color: aqua;
        display: none;
    }

    .date-box-list {
        margin-left: 10px;
        display: flex;
        column-gap: 8px;
    }

    .date-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .date-title-text-cyan,
        .date-title-text-orange {
            font-size: 12px;
            color: #00fefe;
            font-weight: 700;
        }

        .date-title-text-orange {
            color: #ec671b;
        }

        .date-flex {
            display: flex;
            column-gap: 6px;

            .date-content-box {
                background-color: white;
                padding-left: 4px;
                padding-right: 4px;

                p {
                    color: black;
                    font-size: 12px;
                }
            }
        }
    }

    .orange-fest-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 10px;

        img {
            width: 100px;
            height: 16px;
        }

        p {
            color: white;
            font-weight: 700;
            font-size: 10px;
        }
    }

    @media (min-width:1024px) {
        h2 {
            display: block;
        }
    }
}

@media (min-width: 768px) {
    .navbar {
        .account-mobile {
            display: none;
        }
    }
}

@media (min-width: 1100px) {
    .no_scrolled {
        .navbar {
            // position: fixed !important;
        }
    }

    .btn-account {
        margin-left: 10px;
    }

    .container-fluid {
        padding: 0 80px !important;
    }

    .navbar_icons {
        right: 80px !important;
    }

    .banner-top {
        width: 100%;
        min-height: 80px;
        max-height: 80px;
        background-color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
        padding-left: 16px;

        .end-text {
            font-size: 32px;
            font-weight: 700;
            color: white;
            display: block;
        }

        h2 {
            font-size: 32px;
            font-weight: 700;
            color: aqua;
            display: block;
        }

        .date-box-list {
            margin-left: 10px;
            display: flex;
            column-gap: 12px;
        }

        .date-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .date-title-text-cyan,
            .date-title-text-orange {
                font-size: 16px;
                color: #00fefe;
                font-weight: 700;
            }

            .date-title-text-orange {
                color: #ec671b;
            }

            .date-flex {
                display: flex;
                column-gap: 6px;

                .date-content-box {
                    background-color: white;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px;

                    p {
                        color: black;
                        font-size: 14px;
                        font-weight: 900;
                    }
                }
            }
        }

        .orange-fest-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 10px;

            p {
                color: white;
                font-weight: 700;
                font-size: 10px;
                display: none;
            }

            img {
                width: 120px;
                height: 20px;
            }
        }

        @media (min-width:1024px) {
            h2 {
                display: block;
            }
        }
    }
}

@media (min-width: 1440px) {

    .banner-top {
        width: 100%;
        min-height: 100px;
        max-height: 100px;
        background-color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
        padding-left: 16px;

        .end-text {
            font-size: 40px;
            font-weight: 700;
            color: white;
            display: block;
        }

        h2 {
            font-size: 54px;
            font-weight: 700;
            color: aqua;
            display: block;
        }

        .date-box-list {
            margin-left: 10px;
            display: flex;
            column-gap: 18px;
        }

        .date-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .date-title-text-cyan,
            .date-title-text-orange {
                font-size: 22px;
                color: #00fefe;
                font-weight: 700;
            }

            .date-title-text-orange {
                color: #ec671b;
            }

            .date-flex {
                display: flex;
                column-gap: 6px;

                .date-content-box {
                    background-color: white;
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-bottom: 4px;
                    padding-top: 4px;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                        color: black;
                        font-size: 22px;
                        font-weight: 900;
                    }
                }
            }
        }

        .orange-fest-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 10px;

            p {
                color: white;
                font-weight: 700;
                font-size: 10px;
                display: none;
            }

            img {
                width: 240px;
                height: 50px;
            }
        }

        @media (min-width:1024px) {
            h2 {
                display: block;
            }
        }
    }
}