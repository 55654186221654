.car_card_component {
    border: none;
    box-shadow: 0px 2px 4px 0px #efefef;
    position: relative;
    max-width: 330px;
    margin: 0 auto;
    // border: 1px solid red;
    width: 100%;

    .card-body {
        height: 260px;
    }

    .tag {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.4em 0.8em;
        border-top-left-radius: 0.5rem;
    }

    .tag-2 {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.4em 0.8em;
        border-top-left-radius: 0.5rem;
    }

    .img_car {
        width: 100%;
        height: 220px;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        object-fit: cover;
    }

    &:hover {
        text-decoration: none;
        color: inherit;
    }

    .card-price {
        font-size: 22px;
        line-height: 30px;
        color: #1e1e1e;
        font-weight: 700;
    }
    .view-btn {
        width: 91px;
        height: 42px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .warranty {
        background-color: #c5f466;
        width: 208px;
        height: 30px;
        padding: 4px 16px 4px 8px;
        border: 1px solid #1e1e1e;
        // border-radius: 50px;
        color: #1e1e1e;
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        gap: 4px;
        position: absolute;
        top: 8px;
        right: 8px;
    }
    .warranty2 {
        background-color: #c5f466;
        width: 200px;
        height: 30px;
        padding: 4px 16px 4px 8px;
        border: 1px solid #2e8221;
        // border-radius: 50px;
        color: #1e1e1e;
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
        gap: 4px;
        position: absolute;
        top: 8px;
        right: 8px;
    }
}

@media (min-width: 1024px) {
    .car_card_component {
        width: 291px;
    }
}
