button.btn-custom {
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    text-wrap: nowrap;
    padding: 8px 32px;
    background-color: #ec671b;
    height: 48px;
    font-family: "Urbanist";
    text-transform: uppercase;
    border-bottom-left-radius: 2em;
    border-top-right-radius: 2em;
    // border-bottom-right-radius: 4px;
    // border-top-left-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    overflow: hidden;
    word-break: keep-all;

    &:hover {
        background-color: #ec671b;
        color: white;
    }

    &:hover::before {
        position: absolute;
        content: "";
        width: 5em;
        bottom: 0;
        height: 100%;
        background: transparent;
        background: linear-gradient(
            145deg,
            transparent 0%,
            transparent 40%,
            var(--color-blue-neon) 40%,
            var(--color-blue-neon) 43%,
            transparent 43%,
            transparent 46%,
            var(--color-blue-neon) 46%,
            var(--color-blue-neon) 49%,
            transparent 49%,
            transparent 52%,
            var(--color-blue-neon) 52%,
            var(--color-blue-neon) 55%,
            transparent 55%
        );
        animation: animationButton 2s linear infinite;
    }

    @keyframes animationButton {
        0% {
            transform: translateX(-100%) scale(1);
            opacity: 0.3;
        }

        25% {
            transform: translateX(10%) scale(2);
            opacity: 0.7;
        }

        50% {
            transform: translateX(120%) scale(1);
            opacity: 0.3;
        }

        75% {
            transform: translateX(10%) scale(2);
            opacity: 0.7;
        }

        100% {
            transform: translateX(-100%) scale(1);
            opacity: 0.3;
        }
    }
}

button.btn-custom.btn-secondary {
    color: black;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    background-color: white;
    height: 48px;
    font-family: "Urbanist";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 32px;
    border: 2px solid #ec671b;

    &:hover {
        background-color: white;
        border: 2px solid #ec671b;
        color: black;
    }
}

button.btn-custom.btn-cyan {
    color: black;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    background-color: #00fefe;
    height: 48px;
    font-family: "Urbanist";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 32px;
    border: 2px solid transparent;

    &:hover {
        color: black;
    }
}

button.btn-custom.btn-black{
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    background-color: #000;
    height: 48px;
    font-family: "Urbanist";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 32px;
    border: 2px solid transparent;
}

button.btn-custom.btn-tertiary {
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    background-color: #1e1e1e;
    width: 221px;
    height: 48px;
    font-family: "Urbanist";
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ec671b;

    &:hover {
        background-color: #1e1e1e;
        border: 2px solid #ec671b;
        color: white;
    }
}

// Sizes

.btn-medium {
    max-width: 180px;
    height: 44px !important;
    padding: 8px 16px !important;
}

.without-border {
    border: none !important;
}
