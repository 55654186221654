.skeleton_card_component {
    border: none;
    box-shadow: 0px 2px 4px 0px #efefef;
    position: relative;
    max-width: 330px;
    margin: 0 auto;
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    .card-body {
        height: 260px;
    }

    .img_car {
        position: relative;
        width: 100%;
        height: 220px;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        background: #90A3BF;

        &::before {
            content: "";
            position: absolute;
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, #748399, transparent);
            background-size: 200% 200%;
            animation: skeleton-loading 4s infinite linear;
        }

        @keyframes skeleton-loading {
            0% {
                background-position: 0% 0%;
            }

            100% {
                background-position: 100% 0%;
            }


        }
    }

    .loading_effec {
        background: #90A3BF;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, #748399, transparent);
            background-size: 200% 200%;
            animation: skeleton-loading 4s infinite linear;
        }



        @keyframes skeleton-loading {
            0% {
                background-position: 0% 0%;
            }

            50% {
                background-position: 100% 0%;
            }

            100% {
                background-position: 0% 0%;
            }
        }
    }

    .loading_effec-button {
        background: #90A3BF;
        position: relative;
        height: 42px;
        width: 91px;
        border-bottom-left-radius: 2em;
        border-top-right-radius: 2em;

        &::before {
            content: "";
            position: absolute;
            border-bottom-left-radius: 2em;
            border-top-right-radius: 2em;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, #748399, transparent);
            background-size: 200% 200%;
            animation: skeleton-loading 4s infinite linear;
        }



        @keyframes skeleton-loading {
            0% {
                background-position: 0% 0%;
            }

            50% {
                background-position: 100% 0%;
            }

            100% {
                background-position: 0% 0%;
            }
        }
    }
}

@media (min-width: 1024px) {
    .skeleton_card_component {
        width: 291px;
    }
}