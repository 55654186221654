.box_modal_amount {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
    background-color: #00000080;
    justify-content: center;
    align-items: center;
    user-select: none;

    .close {
        position: absolute;
        top: 3em;
        right: 3em;
    }

    .modal-amount-buy {
        margin-top: 0;
        width: 100%;
        max-width: 600px;
        border-radius: 16px;
        padding: 50px 20px;
        background-color: white;
        display: flex;
        flex-direction: column;

        .icon-close {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }

        .modal-buttons {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 10px;

            .color {
                color: #ccc;
            }

            .bg-color {
                background: #ccc;
            }
        }
    }

    .hidden_checkbox::before{
        display: none;
    }

    @media screen and (max-width: 768px) {
        .modal-amount-buy {
            border-radius: 0;

            .modal-buttons {
                flex-direction: column;
                align-items: center;
                gap: 10px;
                margin-top: 12px;
            }
        }
    }
}