// <footer className="footer pt-5 bg_white">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-md-3 col-lg-6 mb-4 mb-md-0">
//                         <img className="mb-3" src="/assets/logos/horizontal.svg" alt="wcar" title="wcar" />
//                         <br />
//                         <span className="d-block">{SLOGAN}</span>
//                         <br />
//                         <LoadScript googleMapsApiKey="AIzaSyD8q92si456MOmd_nZIQnL_EcvPteWc2K8">
//                             <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={9}>
//                                 <Marker position={location1} />
//                                 <Marker position={location2} />
//                                 <Marker position={location3} />
//                             </GoogleMap>
//                         </LoadScript>
//                     </div>
//                     <div className="col-6 col-md-3 col-lg-2">
//                         <div className="d-flex flex-column w-100">
//                             <h6 className="text_orange text_bold mb-3">Menú</h6>
//                             <Link to={routes.aboutUs.relativePath} className="my-1">
//                                 Nuestra Empresa
//                             </Link>
//                             <Link to={routes.buyYourCar.relativePath} className="my-1">
//                                 Compra tu carro
//                             </Link>
//                             <Link to={routes.sellYourCar.relativePath} className="my-1">
//                                 Vende tu carro
//                             </Link>
//                             <Link to={routes.services.relativePath} className="my-1">
//                                 Financiación
//                             </Link>
//                             <Link to={routes.insurance.relativePath} className="my-1">
//                                 Seguros
//                             </Link>
//                             <Link to={routes.procedures.relativePath} className="my-1">
//                                 Trámites
//                             </Link>

//                             <Link to={routes.termAndConditionsUser.relativePath} className="my-1">
//                                 Términos y condiciones usuarios
//                             </Link>
//                             <Link to={routes.termAndConditions.relativePath} className="my-1">
//                                 Términos y Condiciones Garantia
//                             </Link>
//                             {/* <Link to={routes.privacyPolicyMobileApp.relativePath} className="my-1">
//                                 Políticas de privacidad para la aplicación móvil wcar
//                             </Link> */}

//                             <Link to={routes.privacyPolicies.relativePath} className="my-1">
//                                 wcar politica de tratamiento de datos app
//                             </Link>
//                             {termAndConditionsLinks.map((link: any, i: number) => {
//                                 return (
//                                     <>
//                                         {link.title == "Aviso De Privacidad De Datos Para Usuarios App WCAR" ? (
//                                             <h6 className="text_orange text_bold mb-3 mt-3">App Mobile Wcar</h6>
//                                         ) : null}

//                                         <Link
//                                             key={i}
//                                             to={`${window.location.origin}/${link.title
//                                                 .toLowerCase()
//                                                 .normalize("NFD")
//                                                 .replace(/[\u0300-\u036f]/g, "")
//                                                 .replace(/ /g, "-")}/${link.id}`}
//                                             className="my-1"
//                                         >
//                                             {link.title}
//                                         </Link>
//                                     </>
//                                 );
//                             })}
//                             <Link to={routes.deleteAccount.relativePath} className="my-1">
//                                 Eliminación de cuenta <strong>App WCAR</strong>
//                             </Link>

//                             {/* <Link to={routes.blog.relativePath} className="my-1">Blog</Link> */}
//                         </div>
//                     </div>
//                     <div className="col-6 col-md-3 col-lg-2">
//                         <div className="d-flex flex-column w-100">
//                             <h6 className="text_orange text_bold mb-3">Contacto</h6>

//                             <a href="mailto:contacto@wcar.co" className="my-1">
//                                 contacto@wcar.co
//                             </a>
//                             <a
//                                 className="mt-1 font-weight-bold text_bold"
//                                 href="https://maps.app.goo.gl/HDat5NSBR9Ap2PyD8"
//                                 target="_blank"
//                             >
//                                 wcar Morato
//                             </a>
//                             <p>
//                                 Calle 98a # 69B-35
//                                 <br />
//                                 +57 324 4001212
//                             </p>
//                             <a
//                                 className="mb-0 mt-2 font-weight-bold text_bold"
//                                 href="https://maps.app.goo.gl/8NmdBefUDhEUd8Ud7"
//                                 target="_blank"
//                             >
//                                 wcar Sabana.
//                             </a>
//                             <p>
//                                 Carrera 5 #9 - 26 sur, Cajicá, Cundinamarca Torre 3, Local 3. Sabana Park Health &
//                                 Business
//                             </p>
//                             <a
//                                 className="mt-2 font-weight-bold text_bold"
//                                 href="https://maps.app.goo.gl/DuoSfrxDbLMzExZq5"
//                                 target="_blank"
//                             >
//                                 wcar Taller.
//                             </a>
//                             <p>Cra 69b #98-28</p>
//                         </div>
//                     </div>
//                     <div className="col-6 col-md-3 col-lg-2">
//                         <div className="d-flex flex-column w-100">
//                             <h6 className="text_orange text_bold mb-3">Siguenos</h6>
//                             <div
//                                 style={{
//                                     display: "flex",
//                                     justifyContent: "flex-start",
//                                     alignItems: "center",
//                                 }}
//                             >
//                                 <BiLogoFacebookCircle fontSize={20} />
//                                 <a href="https://www.facebook.com/wcarcolombia?mibextid=ZbWKwL" className="my-2 mx-1">
//                                     Facebook
//                                 </a>
//                             </div>
//                             <div
//                                 style={{
//                                     display: "flex",
//                                     justifyContent: "flex-start",
//                                     alignItems: "center",
//                                 }}
//                             >
//                                 <BiLogoInstagram fontSize={20} />
//                                 <a
//                                     href="https://www.instagram.com/wcar.oficial?igshid=NGVhN2U2NjQ0Yg%3D%3D"
//                                     className="my-2 mx-1"
//                                 >
//                                     Instagram
//                                 </a>
//                             </div>
//                             <div
//                                 style={{
//                                     display: "flex",
//                                     justifyContent: "flex-start",
//                                     alignItems: "center",
//                                 }}
//                             >
//                                 <BiLogoYoutube fontSize={20} />
//                                 <a
//                                     href="https://youtube.com/@wcarcolombia?si=SAfvApSkimvFnxHK"
//                                     target="_blank"
//                                     rel="noreferrer"
//                                     className="my-2 mx-1"
//                                 >
//                                     Youtube
//                                 </a>
//                             </div>
//                             <div
//                                 style={{
//                                     display: "flex",
//                                     justifyContent: "flex-start",
//                                     alignItems: "center",
//                                 }}
//                             >
//                                 <BiLogoLinkedin fontSize={20} />
//                                 <a
//                                     href="https://www.linkedin.com/company/wcarcol/mycompany/"
//                                     target="_blank"
//                                     rel="noreferrer"
//                                     className="my-2 mx-1"
//                                 >
//                                     <span className="mt-3">Linkedin</span>
//                                 </a>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="container">
//                 <div className="row d-flex flex-column-reverse flex-md-row pt-3 pb-md-5">
//                     <div className="col-md-4 d-flex flex-column aling-items-center footer_sic align-items-md-start text_gray py-3 pb-md-0">
//                         <img
//                             src="/assets/logos/industria_y_comercio.png"
//                             alt="wcar"
//                             title="wcar"
//                             className="img_fluid mb-3 px-5 px-md-0"
//                         />
//                         <div className="text-center text-md-start">
//                             <span className="text_wcar">wcar</span> -<span> Todos los derechos reservados © 2024</span>
//                         </div>
//                     </div>
//                     <div className="col-md-8 d-flex pb-5 pb-md-0">
//                         <div className="w-100 text_underline text_gray d-flex flex-column flex-md-row align-items-center align-items-md-end">
//                             <div className="mx-3 mt-3 mt-md-0">
//                                 <Link to={routes.generalPolicies.relativePath}>Políticas y tratamiento de datos</Link>
//                             </div>

//                             <div className="mx-3 mt-3 mt-md-0">
//                                 <Link to={routes.buyerPolicies.relativePath}>Términos y condiciones comprador</Link>
//                             </div>
//                             <div className="mx-3 mt-3 mt-md-0">
//                                 <Link to={routes.sellerPolicies.relativePath}>Términos y condiciones vendedor</Link>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </footer>

footer {
    width: 100%;
    background: #f6f7f9;
    padding: 40px 0px 0px 0px;
}

.box-divider {
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
}

.divider {
    max-width: 170px;

    h6 {
        color: #ec671b;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .title{
        color: #ec671b;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    ul {
        padding: 0;
    }

    ul li {
        list-style: none;
        margin-top: 16px;
        color: #666c89;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
    }

    ul > li.address p span {
        color: #90a3bf;
    }
}

.divider-app {
    margin-top: 48px;
    max-width: 100%;
    padding: 0 32px;
}

.divider-terms-conditions {
    margin-top: 40px;
    max-width: 100%;
    padding: 0 32px;
}

.divider-last {
    margin-top: 40px;
    padding: 0 32px;
    padding-bottom: 48px;
    max-width: 100%;

    ul li {
        color: #90a3bf;
    }
}

.store-container {
    padding: 0 32px;
    display: flex;
    column-gap: 16px;
    height: 47px;
    margin-top: 40px;
}

.container-map {
    margin-top: 48px;
    min-width: 100%;
    display: flex;
    justify-content: center;
}

.container-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;

    & > div.box-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.logo {
    width: 161px;
    height: 64px;
}

.slogan-text {
    color: #666c89;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    p {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: #ec671b;
    }

    .sm-enlaces {
        display: flex;
        column-gap: 16px;
        margin-left: 16px;

        .sm-item {
            width: 38px;
            height: 38px;
            border: 1px solid #90a3bf;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}

.container-map-desk,
.store-container-desk {
    display: none;
}

.divider-last-desk {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: none;
    position: relative;
    z-index: 1;

    ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1;

        li {
            margin-top: 0;
        }
    }

    .bg-white {
        background-color: white;
        position: absolute;
        width: 100vw;
        height: 100%;
        left: 0;
        bottom: 0;
        z-index: -1;
    }
}

.box-industria-comercio {
    margin: auto;
    align-self: center;
    background-color: #fff;
    padding: 40px 0;

    div {
        display: flex;
    }

    img {
        width: 100%;
        height: 40px;
    }

    .text-rights {
        text-wrap: nowrap;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: #90a3bf;
    }
}

@media (min-width: 1024px) {
    .container-map {
        display: none;
    }

    .divider-last {
        display: none;
    }

    .store-container {
        display: none;
    }

    .enlaces-wrapper {
        display: flex;
        max-width: 1320px;
        margin: auto;
        justify-content: center;
    }

    footer {
        padding-top: 64px;
    }

    .box-divider {
        column-gap: 24px;
        padding: 0;
        flex: 1.3;
    }

    .divider:nth-child(1) {
        min-width: 179px;
        flex: 1;
    }

    .divider:nth-child(2) {
        min-width: 280px;
        flex: 1;
    }

    .divider-app {
        margin-top: 0;
        // min-width: 280px;
        padding: 0;
        margin-left: 24px;
        flex: 1;
    }

    .divider-terms-conditions {
        margin-top: 0;
        // min-width: 379px;
        padding: 0;
        flex: 1;
        margin-left: 24px;
    }

    .secondary-section {
        display: flex;
        max-width: 1320px;
        margin: auto;
        justify-content: center;
        padding-bottom: 48px;
        border-bottom: 1px solid #90a3bf;
        // border: 1px solid red;
    }

    .container-logo {
        flex: 1;
        justify-content: flex-start;
        align-items: flex-start;

        & > div.box-logo {
            align-items: flex-start;
        }
    }

    .social-media {
        margin-top: 16px;
    }

    .container-map-desk {
        display: flex;
        width: 582px;
        max-width: 582px;
        height: 167px;
        max-height: 167px;
        align-self: flex-end;
        margin: auto;
        margin-right: 73px;
    }

    .store-container-desk {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        row-gap: 22px;
    }

    .divider-last-desk {
        // border: 1px solid red;
        max-width: 1320px;
        margin: auto;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: 32px;
        padding-bottom: 64px;
        justify-content: space-between;

        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            margin-left: 20%;

            li {
                margin-top: 0;
            }
        }

        .bg-white {
            display: none;
        }
    }

    .box-industria-comercio {
        background-color: transparent;
        padding: 0;

        div {
            display: flex;
        }

        img {
            width: 180px;
            height: 40px;
        }

        .text-rights {
            text-wrap: nowrap;
            font-size: 14px;
            line-height: 22px;
            font-weight: 500;
            color: #90a3bf;
        }
    }
}
