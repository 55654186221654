.banner_financing {
    background-color: #000;
    margin-top: 20px;

    .container {
        min-height: 400px;
    }

    .title {
        font-size: 50px;
        font-weight: 700;
        color: rgb(236, 103, 27);
        font-family: "Trebuchet MS", sans-serif;

        i {
            font-weight: 300;
            font-family: "Trebuchet MS", sans-serif;
            color: rgb(0, 254, 254);
        }
    }

    .card_financing {
        width: 320px;
        background: #fff;
        border-radius: 4px;

        .title_container {
            padding: 18px 24px 0 24px;
            font-size: 28px;

            p {
                font-weight: 800;
            }

            i {
                font-weight: 500;
            }
        }

        .sub_title_container {
            padding: 8px 20px;
            display: flex;
            align-items: start;
            gap: 16px;

            p {
                font-size: 22px;
                color: #000;
            }
        }

        .item_container {
            padding: 0 24px;

            p {
                font-size: 18px;
                color: #2b2b2b;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                column-gap: 16px;
            }
        }

        .button_container {
            padding: 24px;
        }
    }
}