.loading_component{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &>.logo{
        margin: 1em;
        width: 10em;
        // height: 3em;
        border-radius: 5px;
    }
    .loading-dots {
        display: flex;
        justify-content: center;
        align-items: center;
      
        .dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin: 0 8px;
            background-color: #D1D1D1;
            animation: dot-bounce 1.4s infinite ease-in-out;
        }
      
        .dot:nth-child(1) {
            animation-delay: -0.32s;
        }
      
        .dot:nth-child(2) {
            animation-delay: -0.16s;
        }
      
        @keyframes dot-bounce {
      
            0%,
            80%,
            100% {
                transform: scale(0);
                opacity: 0;
            }
      
            40% {
                transform: scale(1);
                opacity: 1;
            }
        }
      }
      
}