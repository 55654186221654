.buy_your_car_page {
    .scroll-filter {
        overflow: scroll;
        max-height: 78vh;

        right: 10px;
        top: 60px;
        position: absolute;
        background-color: white;
        padding-right: 8px;
    }

    .bg-filters {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1000;
        overflow: hidden;
    }

    .bg_search {
        background-image: url(../../../../../../public/assets/recs/bg_search.png);
        background-repeat: no-repeat;
        background-size: cover;

        .input_search {
            background-color: white;
            border: solid thin black;
            border-radius: 2.5em;
            padding: 0.5em;
            display: flex;
            align-items: center;
            max-width: 100%;
            min-width: 350px;

            input {
                border: none;
                flex-grow: 1;
                margin-left: 0.5em;

                &:focus,
                &:active,
                &:focus-visible {
                    outline: none;
                    border: none;
                }
            }
        }

        .order_by_container {
            color: white;

            .btn {
                background-color: transparent;
                border: none;
                padding-right: 2em;

                &.dropdown-toggle::after {
                    margin-left: -2em;
                }

                .order_button {
                    background-color: #666c8940;
                    padding: 0.8em 2em 0.8em 1em;
                    border-radius: 0.5em;
                    margin-right: 1em;
                    color: white !important;
                }

                .dropdown-toggle::after {
                    margin-left: -2em;
                    background-color: white !important;
                    color: white !important;
                }
            }
        }
    }

    .order-text {
        color: white !important;
    }

    .car_list {
        position: relative;
        background-image: url(../../../../../../public/assets/recs/bg_car_list.svg);
        background-size: cover;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            background-color: white;
        }
    }

    @media (min-width: 1024px) {
        .car_list {
            margin: 0 auto;
            padding: 0 32px;
        }
    }

    @media (min-width: 1280px) {
        .car_list {
            padding: 0 32px;
        }
    }

    @media (min-width: 1366px) {
        .car_list {
            padding: 0 40px;
        }
    }

    @media (min-width: 1400px) {
        .car_list {
            padding: 0 80px;
        }
    }

    @media (min-width: 1500px) {
        .car_list {
            padding: 0 124px;
        }
    }

    @media (min-width: 1600px) {
        .car_list {
            padding: 0 240px;
        }
    }

    @media (min-width: 2000px) {
        .car_list {
            max-width: 1320px;
            min-width: 1320px;
            margin: 0 auto;
            padding: 0;
        }
    }
}

.filter-btn-visible {
    display: flex;
}

.filter-btn-hidden {
    display: flex;
}

// .car_seo {
//     h1 {
//         font-size: 10px !important;
//     }
//     h2 {
//         font-size: 8px;
//     }
//     h3 {
//         font-size: 8px;
//     }
//     p {
//         font-size: 6px;
//     }
// }

.active-custom{
    background-color: #ec671b !important;
    color: white !important;
}

@media (min-width: 768px) {
    .container-drop {
        display: flex;
        justify-content: center;
    }

    .filter-btn-hidden {
        display: none;
    }

    .buy_your_car_page {
        .scroll-filter {
            overflow: hidden;
            overflow-y: scroll;
            max-height: 100vh;
            left: 0;
            right: 0;
            top: 0;
            position: sticky;
            width: 100%;
            padding: 0;

        }

        .scroll-filter::-webkit-scrollbar {
            background: white;
            width: 12px;
        }

        .scroll-filter::-webkit-scrollbar-thumb {
            background: #f1f1f1;
            border-radius: 10px;
            width: 3px;
            height: 3px;
        }
    }

    .bg-filters {
        display: none;
    }
}