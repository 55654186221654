.modals_component {
    .contact_fixed {
        position: fixed;
        right: 0;
        z-index: 103;
        top: 37%;
        height: fit-content;
        transform: translateX(40%) rotate(90deg);
    }

    .Toastify__toast-container--top-right {
        top: 6em;
    }
}

#windows {
    .modals_component{
        .contact_fixed {
            right: 20px;
        }
    }
}